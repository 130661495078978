import * as React from 'react'
import {Link} from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'

export default function CommunityAccessPage() {
  return (
    <div className="has-background-primary has-text-white">
      <SEO
        title="Acceder a la Comunidad"
        description="Únete a nuestra comunidad en Discord"
        hasJsonLd
      />
      <div className="has-text-white section">
        <div style={{padding: '38px', height: '100vh'}} className="container">
          <div
            className="columns is-centered is-vcentered has-text-white"
            style={{height: '100%'}}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 28,
              }}
              className="column is-half has-text-centered"
            >
              <h1 className="title has-text-white">Acceder a la Comunidad</h1>
              <p className="subtitle has-text-white">
                Únete a nuestra comunidad en Discord y conéctate con otros
                miembros.
              </p>
              <a
                href="https://discord.gg/BqJxSvYepH"
                className="button is-light is-large"
                style={{
                  borderRadius: '50px',
                  padding: '20px 40px',
                  transition: 'background-color 0.3s, transform 0.3s',
                }}
                onMouseEnter={e => {
                  e.target.style.backgroundColor = '#3273dc'
                  e.target.style.color = '#fff'
                  e.target.style.transform = 'scale(1.05)'
                }}
                onMouseLeave={e => {
                  e.target.style.backgroundColor = ''
                  e.target.style.color = ''
                  e.target.style.transform = 'scale(1)'
                }}
              >
                Acceder a la Comunidad
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
